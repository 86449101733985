import initNav from '~/javascripts/nav.js'
import initCarousel from '~/javascripts/carousel.js'
import initDarkModeToggle from '~/javascripts/dark_mode_toggle.js'
import initMap from '~/javascripts/map.js'
import initProgressMeter from '~/javascripts/progress_meter.js'
import initSocialCard from '~/javascripts/social_card.js'

document.addEventListener('DOMContentLoaded', () => {
  initNav()
  initCarousel()
  initDarkModeToggle()
  initMap()
  initProgressMeter()
  window.initSocialCard = initSocialCard
})

document.addEventListener('colorschemechange', (e) => {
  // ISSUE: on Chrome/Safari only changes the pin does not change map colours
  //   related?: https://issues.chromium.org/issues/41451306
  initMap()
})

